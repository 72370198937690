import React, { useState } from "react";
import SimpleReactValidator from 'simple-react-validator'
import './style.css'

const useValidator = (customMessage = {}, customValidator = {}) => {
    const [show, setShow] = useState(false)
    const validator = new SimpleReactValidator({
        messages:  <span>{customMessage}</span>,
        validators: customValidator
    })

    if (show) {
        validator.showMessages()
    }

    return [validator, setShow]
}

export default useValidator