import React from 'react';
import './ThankYouPage.css'; // Style the Thank You page

const ThankYouPage = () => {
  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h1 className="thank-you-title">Thank You!</h1>
        <p className="thank-you-message">
          You’ve successfully joined the wishlist. We’ll keep you updated with the latest news and announcements.
        </p>
        <button
          className="back-home-button"
          onClick={() => (window.location.href = '/')}
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
