import React, { useState } from "react";
import emailjs from 'emailjs-com';
import toast, { Toaster } from "react-hot-toast";
import './style.css';
import useValidator from "./useValidator";
import { errorMessageToDisplay } from "./helperText";

const serviceId = `${process.env.REACT_APP_SERVICE_ID}`;
const templateID = `${process.env.REACT_APP_TEMPLATE_ID}`;
const appKey = `${process.env.REACT_APP_APP_KEY}`;

console.log(serviceId, templateID, appKey, "asdfdsf")

export const ContactForm = () => {
  const [validator, showValidationMessage] = useValidator();
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    phoneNumber: '',
    location: '',
    message: ''
  });
  const [validEmail, setValidEmail] = useState(true);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (name === "email") {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const isValid = emailRegex.test(value);
      setValidEmail(isValid);
    }
  };

  const handleSubmit = (e) => {
    if (validator.allValid()) {
      e.preventDefault();

      const templateParams = {
        ...formData,
      };

      // Send email using EmailJS
      emailjs
        .send(serviceId, templateID, templateParams, appKey)
        .then((response) => {
          // Track successful form submission with GA4
          window.gtag('event', 'form_submission', {
            event_category: 'engagement',
            event_label: 'Contact Form',
            value: 1, // Custom value if applicable
          });

          window.location.href = "/thank-you";
          setFormData({
            userName: '',
            email: '',
            phoneNumber: '',
            location: '',
            message: ''
          });
          showValidationMessage(false);
        })
        .catch((error) => {
          console.error(error);

          // Track failed form submission with GA4
          window.gtag('event', 'form_submission_failed', {
            event_category: 'engagement',
            event_label: 'Contact Form',
            value: 0,
          });

          toast.error('Email sending failed', { duration: 3000 });
        });
    } else {
      showValidationMessage(true);
    }
  };

  return (
    <div className="form-mainContainer">
      <Toaster />
      <div className="input-rows">
        <div className="input-wrapper">
          <input
            className="normal-input"
            name="userName"
            type="text"
            placeholder="Enter name"
            onChange={handleChange}
            value={formData.userName}
          />
          {errorMessageToDisplay(validator, "Name", formData?.userName, "required|min:3|max:100")}
        </div>

        <div className="input-wrapper">
          <input
            className="normal-input"
            name="email"
            type="text"
            placeholder="Enter email"
            onChange={handleChange}
            value={formData.email}
          />
          {errorMessageToDisplay(validator, "Email", formData?.email, "required")}
        </div>
      </div>
      <div className="input-rows">
        <div className="input-wrapper">
          <input
            className="normal-input"
            name="phoneNumber"
            type="number"
            placeholder="Your Phone"
            onChange={handleChange}
            value={formData.phoneNumber}
          />
          {errorMessageToDisplay(validator, "Phone Number", formData?.phoneNumber, "required|min:10|max:15")}
        </div>
        <div className="input-wrapper">
          <input
            className="normal-input"
            name="location"
            type="text"
            placeholder="Location"
            onChange={handleChange}
            value={formData.location}
          />
          {errorMessageToDisplay(validator, "Location", formData?.location, "required|min:3|max:100")}
        </div>
      </div>
      <div className="input-rows">
        <div className="textarea-wrapper">
          <textarea
            className="textarea"
            name="message"
            rows={5}
            placeholder="Message"
            onChange={handleChange}
            value={formData.message}
          />
          {errorMessageToDisplay(validator, "Message", formData?.message, "required|min:3|max:100")}
        </div>
      </div>
      <div className="input-rows">
        <button
          type="button"
          className="submit-button"
          onClick={handleSubmit}
        >
          Send Message
        </button>
      </div>
    </div>
  );
};
